@import-normalize;

:root {
  --border-radius: 8px;
  --border-radius-sm: 3px;

  --box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
  --box-shadow-sm: 0 1px 2px 0 rgb(0 0 0/8%);

  --color-background: #fff;
  --color-background-300: rgb(252, 252, 253);
  --color-background-500: rgb(243, 243, 243);
  --color-border: rgb(230, 230, 230);
  --color-border-300: rgb(200, 200, 200);
  --color-link: #337ab7;
  --color-link-focus: #23527c;
  --color-text: rgb(50, 49, 48);
  --color-text-muted: #666;

  --font-size: 14px;
  --font-size-sm: 11px;
  --font-size-lg: 21px;
  --font-weight-bold: 600;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  color: var(--color-text);
  font-family: "Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  font-size: var(--font-size);
  -webkit-font-smoothing: antialiased;
  line-height: 1.42857143;
}

a {
  color: var(--color-link);
  cursor: pointer;
}

a:link,
a:focus,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: var(--color-link-focus);
}

hr {
  border: 0;
  border-top: 1px solid var(--color-border-300);
}

img.img-48 {
  height: 48px;
  width: 48px;
}

img.img-96 {
  height: 96px;
  width: 96px;
}

.ms-Button--icon {
  color: var(--color-text);
}

.ms-Button--icon:hover {
  background-color: transparent !important;
}

.flex-container-horz {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-container-horz.center {
  justify-content: center;
}

.flex-container-vert {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-container-vert.center {
  justify-content: center;
}

.flex-grow {
  flex-grow: 1;
}

.height-100p {
  height: 100%;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.bg-default {
  background-color: var(--color-background);
}

.bg-secondary {
  background-color: rgba(255, 255, 255, 0.7);
}

.no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-muted {
  color: var(--color-text-muted);
}

.section {
  padding: 30px 0;
}

.section.section-sm {
  padding: 12px 0;
}

.visible-sm {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .hidden-sm {
    display: none !important;
  }

  .visible-sm {
    display: initial !important;
  }
}