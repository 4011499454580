.attachments-list {
    display: flex;
    flex-wrap: wrap;
    margin: -4px -4px 0;
}

.attachments-list .attachment {
    display: flex;
    max-width: 280px;
    align-items: center;
    color: var(--color-text);
    background-color: var(--color-background);
    border: 1px solid var(--color-border);
    border-radius: 5px;
    overflow: hidden;
    padding: 4px 12px 4px 6px;
    margin: 4px;
    cursor: pointer;
}

.attachments-list .attachment > .details {
    overflow: hidden;
}

.attachments-list .attachment > .details .size {
    font-size: var(--font-size-sm);
}

.attachments-list .attachment.image {
    background-color: transparent;
    border: 0;
    max-height: 400px;
    max-width: 400px;
    padding: 0;
}