.avatar-stack {
    display: flex;
    flex-direction: row-reverse;
}

.avatar-stack > .avatar {
    margin-left: -12px;    
}

.avatar-stack > .avatar:last-child {
    margin-left: 0;
}

.avatar-stack > .avatar .ms-Persona-coin {
    border: 2px solid #fff;
    border-radius: 100%;
}