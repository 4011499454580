.welcome-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
    margin-bottom: 24px;
}

.welcome-message .members {
    margin-bottom: 12px;
}

.welcome-message .greeting, .welcome-message .introduction {
    line-height: 1.8;
}

.welcome-message .reply-time {
    margin-top: 12px; 
    background-color: var(--color-background-500);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-sm);
    padding: 6px 12px;
}