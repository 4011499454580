.message {
    padding-bottom: 4px;
}

.message-bubble {
    background-color: var(--color-background);
    border-radius: 8px;
    box-shadow: var(--box-shadow-sm);
    padding: 6px 0;
}

    .message-group.inverse .message-bubble {
        background-color: var(--accentcolor);
        color: var(--accentcolor-text);
    }

    .message-bubble > .body, .message-bubble > .header {
        padding: 6px 15px;
    }

    .message-bubble > .attachments {
        padding: 6px 15px 0 15px;
    }

.message-bubble > .header {
    font-size: 12px;
}

.message .emoji.emoji-lg {
    font-size: 36px;
}