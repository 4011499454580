.profile-sidebar {
    display: flex;
    flex-direction: column;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    width: 270px;
    min-width: 270px;
    height: 100%;
}

.profile {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 24px 18px 24px;
}

.profile > .body {
    flex-grow: 1;
}

.profile>.body .biography {
    line-height: 1.6em;
}

.profile>.body .name {
    margin-top: 12px;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
}

.profile>.body .job-title {
    color: var(--color-text-muted);
}

.profile .online-profiles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 12px;
}

.profile .online-profiles > a {
    display: flex;
    align-items: center;
    color: var(--color-text-muted);
    padding: 0 12px;
}

.profile .online-profiles>a>.logo {
    height: 24px;
    width: 24px;
}

.profile .online-profiles>a>.label {
    margin-left: 6px;
}

.profile .profile-link {
    color: var(--color-text-muted);
}

.profile .profile-prop {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.profile .profile-prop:last-child {
    margin-bottom: 0;
}