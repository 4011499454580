.message-composer {
    background-color: var(--color-background);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin: 0 12px 12px 12px;
    padding: 6px 12px;
    z-index: 1;
}

.message-composer .input-container {
    overflow: hidden auto;
    max-height: 140px;
}

.message-composer .input .ms-TextField-fieldGroup {
    min-height: auto;
}

.message-composer .input .ms-TextField-fieldGroup::after {
    inset: 0;
}