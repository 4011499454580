.product-link {
    display: flex;
    align-items: center;
    border-radius: 20px;
    color: var(--color-text-muted);
    font-size: var(--font-size-sm);
    padding: 6px 15px;
    transition: all 300ms ease 0s;
}

.product-link:hover {
    background-color: var(--color-background);
    color: var(--color-text-muted);
}

.product-link .logo {
    height: 16px;
    width: 16px;
    margin-right: 6px;
}