.message-scroll-container {
    position: relative;
}

.message-scroll-container.scrolled {
    border-top: 1px solid var(--tertiary-backgroundcolor);
}

.message-container {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 12px 24px;
    min-height: 100%;
}