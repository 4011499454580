.modal-layout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 650px;
    max-height: 95%;
    width: 95%;
}

.modal-layout {
    flex-grow: 1;
    width: 100%;
    max-width: 850px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    overflow: hidden;
}

.modal-layout .modal-body {
    background-color: var(--color-background);
    height: 100%;
    padding: 24px;
}

@media only screen and (max-width: 768px) {
    .modal-layout-container, .app.embed .modal-layout-container {
        height: 100%;
        max-height: 100%;
        width: 100%;
    }    

    .modal-layout {
        border-radius: 0;
    }
}