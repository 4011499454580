.type-indicator {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 12px;
    left: 24px;
}

.type-indicator > .body {
    display: flex;
    align-items: center;
    background-color: var(--color-background);
    border-radius: 8px;
    padding: 6px 15px;
    margin-left: 12px;
}

.type-indicator > .body > img {
    height: 24px;
    width: 24px;
}