.conversation-header {
    padding: 8px 12px 8px 24px;
    min-height: 49px;
}

    .conversation-header .name {
        font-weight: 600;
        font-size: 17px;
    }

    .conversation-header .description {
        color: var(--color-text-muted);
    }

    .conversation-header .verified {
        display: flex;
        align-items: center;
    }

    .conversation-header .verified > .badge {
        height: 21px;
        width: 21px;
        margin-right: 6px;
    }

    .conversation-header .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
    }

    .conversation-header .logo-container > .logo {
        max-width: 21px;
        max-height: 21px;
    }