.message-group {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 12px;
}

    .message-group.inverse {
        flex-direction: row-reverse;
        align-self: flex-end;
    }

.message-group .avatar {
    margin-right: 12px;
    margin-bottom: 26px;
}

.message-group.message-group.inverse .avatar {
    margin-right: 0;
    margin-left: 12px;
}

.message-group .messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80%;
}

    .message-group.inverse .messages {
        align-items: flex-end;
    }